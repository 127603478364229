import { Suspense, lazy, useMemo } from 'react';
import { useGetInitialDataFromHost } from './Hooks/useGetInitialDataFromHost';
import { UnknownErrorOccuredDialogue } from './Components/Error/Dialogue/UnknownErrorOccuredDialogue';
import { getReferrerCheck } from './Utils/getAppConfig';
import { AppLoading } from './Components/AppLoading';

const AppLazy = lazy(() => import('./App'));

export const AppSuspense = () => {
  const initialData = useGetInitialDataFromHost();

  const { isValid: isValidInitialData, isLoaded } = initialData;
  const isValidReferrer = getReferrerCheck();

  const isAccessDenied = useMemo(
    () => !isValidInitialData || !isValidReferrer,
    [isValidInitialData, isValidReferrer]
  );

  const errorMessage = () => {
    const result = [];
    if (!isValidInitialData) {
      result.push('access app: initial data missing (caseUniqueIds)');
    }
    if (!isValidReferrer) {
      result.push('access app: invalid referrer');
    }
    return result.join(',');
  };

  if (isAccessDenied && isLoaded)
    return <UnknownErrorOccuredDialogue errorMessage={errorMessage()} />;
  else {
    console.debug('#APP MOUNTED');
    return (
      <Suspense fallback={<AppLoading />}>
        <AppLazy initialData={initialData} />
      </Suspense>
    );
  }
};
