import { IConfig } from '../Atoms/config';
import { IUser } from '../Atoms/user';
import { APP_CONFIG } from '../Constants/config';
import { ALLOW_ORIGIN_LIST } from '../Constants/iframe';
import { AccessEnv } from '../config';
import { getReferrer } from './getReferrer';

export const getAppConfig = (user: IUser['user'], isMultiple: boolean) => {
  // const { tenantId } = user;

  const isElectronApp = navigator.userAgent.includes('Electron');

  const config = APP_CONFIG[isElectronApp ? 'DENTBIRD_BATCH' : 'DENTBIRD'][
    isMultiple ? 'MULTIPLE' : 'SINGLE'
  ] as unknown as IConfig;
  return config;
};

export const getReferrerCheck = () => {
  // const referrer = getReferrer();
  // if (AccessEnv(['local', 'dev'])) return true;
  // else return ALLOW_ORIGIN_LIST.includes(referrer);
  return true;
};
