import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { userAtom } from '../Atoms/user';
import { AppLoading } from '../Components/AppLoading';
import { useGetUser } from '../Hooks/ReactQuery/useGetUser';
import i18n from '../i18n';

interface IRawUser {
  setting: {
    Crown_axis_configuration: string;
    cam_sw_network_millbox_port: string;
    dental_notation: string;
    export_meshFormat: string;
    language: string;
    namingPresets_fileNamePrefixes_combinedString: string;
    namingPresets_fileNamePrefixes_selectedElement: string[];
    namingPresets_zipFileName_combinedString: string;
    namingPresets_zipFileName_selectedElement: string[];
  };
  user: {
    tenantId: string;
    email: string;
    _id: string;
    language: string;
  };
}

const getRegularizeUser = (response: IRawUser) => {
  const { setting, user } = response || {};
  const {
    Crown_axis_configuration: crownAxisConfiguration,
    cam_sw_network_millbox_port: camSWNetworkMillboxPort,
    dental_notation: dentalNotation,
    export_meshFormat: exportMeshFormat,
    language,
    namingPresets_fileNamePrefixes_selectedElement: fileNamePrefixPreset,
    namingPresets_zipFileName_selectedElement: zipFileNamePreset
  } = setting || {};

  const regularizeSetting = {
    crownAxisConfiguration,
    camSWNetworkMillboxPort,
    dentalNotation,
    exportMeshFormat,
    language,
    fileNamePrefixPreset,
    zipFileNamePreset
  };
  return {
    user,
    setting: regularizeSetting
  };
};

export const Auth = ({ children }: { children: null | React.ReactNode }) => {
  const { isSuccess, isLoading, data } = useGetUser();
  const setUser = useSetRecoilState(userAtom);
  useEffect(() => {
    const user = getRegularizeUser(data);
    i18n.changeLanguage(user?.setting?.language);
    setUser(user);
  }, [data, setUser]);

  if (!isSuccess || isLoading) return <AppLoading />;
  else return <>{children}</>;
};
