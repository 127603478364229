import { atom } from 'recoil';

export interface IUser {
  setting: {
    crownAxisConfiguration: string;
    camSWNetworkMillboxPort: string;
    dentalNotation: string;
    exportMeshFormat: string;
    language: string;
    fileNamePrefixPreset: string[];
    zipFileNamePreset: string[];
  };
  user: {
    tenantId: string;
    email: string;
    _id: string;
    language: string;
  };
}

export const userAtom_initialState = {
  setting: {
    crownAxisConfiguration: '',
    camSWNetworkMillboxPort: '',
    dentalNotation: '',
    exportMeshFormat: '',
    language: '',
    fileNamePrefixPreset: [],
    zipFileNamePreset: []
  },
  user: {
    tenantId: '',
    email: '',
    _id: '',
    language: ''
  }
};
export const userAtom = atom<IUser>({
  key: 'user',
  default: userAtom_initialState
});
