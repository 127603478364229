import {
  Cicon,
  Dialogue,
  DialogueActions,
  DialogueTitle,
  Typography
} from '@imago-cloud/design-system';

import { Stack } from '@mui/material';
import { onCloseApp, onError } from '../../../Utils/postMessage';
import { useRecoilValue } from 'recoil';
import { exportDialogueAtom } from '../../../Atoms/exportDialogue';
import { AccessEnv } from '../../../config';

export const UnknownErrorOccuredDialogue = ({
  errorMessage
}: {
  errorMessage?: string;
}) => {
  const { isUnknownError } = useRecoilValue(exportDialogueAtom);
  const onClose = () => {
    onError();
    onCloseApp();
  };

  return (
    <Dialogue
      open={isUnknownError || !!errorMessage}
      disableBackdropClick
      PaperProps={{
        sx: {
          width: '880px !important',
          maxWidth: '880px',
          height: '634px',
          maxHeight: '634px !important',
          margin: '0px'
        }
      }}
    >
      <DialogueTitle
        isClose
        onClose={onClose}
        sx={{ padding: '22px 20px 14px 28px' }}
      />
      <DialogueActions
        sx={{
          width: '100%',
          height: '566px',
          padding: '0px',
          border: 'none'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            gap: '8px',
            paddingTop: '203px',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Cicon icon="Warning" svgProps={{ width: '36px', height: '36px' }} />
          <Typography variant="H24">An unknown error has occurred.</Typography>
          {AccessEnv(['local', 'dev', 'qa']) && errorMessage && (
            <Typography variant="Body14">ERROR: {errorMessage}</Typography>
          )}
        </Stack>
      </DialogueActions>
    </Dialogue>
  );
};
