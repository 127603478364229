import { atom } from 'recoil';

export interface IExportDialogue {
  isLoading: boolean;
  isUnknownError: boolean;
  isMultiple: boolean;
  isAllowDownload: boolean;
  isExporting: boolean;
  isPaymentLoading: boolean;
  isOnlyScanMeshCase: boolean;
  exportProgressList: {
    caseUniqueId: string;
    caseName: string;
  }[];
  isDataLostDialogueOpen: boolean;
  failedList: { caseUniqueId: string; caseName: string }[];
}

export const exportDialogueAtom_initialState = {
  isLoading: true,
  isUnknownError: false,
  isMultiple: false,
  isAllowDownload: true,
  isExporting: false,
  isPaymentLoading: false,
  isOnlyScanMeshCase: false,
  exportProgressList: [],
  isDataLostDialogueOpen: false,
  failedList: []
};
export const exportDialogueAtom = atom<IExportDialogue>({
  key: 'export-dialogue',
  default: exportDialogueAtom_initialState
});
