import { useQuery } from '@tanstack/react-query';
import { axios } from '../../Auth/Axios/axiosInstance';
import { USER_API } from '../../Constants/Api/apiEndpoints';

export const useGetUser = () => {
  return useQuery(
    ['get-user'],
    () =>
      axios.get(USER_API).then(({ data }) => {
        const {
          user: { _id }
        } = data || {};
        axios.defaults.headers.common['x-user-id'] = `${_id}`;
        return data;
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 100000
    }
  );
};
