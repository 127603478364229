import axios, { AxiosInstance } from 'axios';

export const createAxiosInstance = ({
  baseURL,
  requestAccessToken
}: {
  baseURL: string;
  requestAccessToken: () => Promise<string | null>;
}): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  let accessToken: string | null = null;

  instance.interceptors.request.use(
    async (config) => {
      if (accessToken === null) {
        accessToken = await requestAccessToken();
        if (accessToken) {
          instance.defaults.headers.common['Authorization'] =
            `Bearer ${accessToken}`;
        }
      }
      config.headers = config.headers || {};
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;

      if (error.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        accessToken = await requestAccessToken();
        if (accessToken) {
          instance.defaults.headers.common['Authorization'] =
            `Bearer ${accessToken}`;
          originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;
          return instance(originalConfig);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
