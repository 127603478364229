import {
  FormProvider as ReactHookFormProvider,
  useForm
} from 'react-hook-form';
import { FormExportContext } from './Context/formContext';
import { FORM_DEFAULT_VALUES, IForm } from './Constants/form';

export const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const methods = useForm<IForm>({
    defaultValues: FORM_DEFAULT_VALUES
  });
  return (
    <FormExportContext.Provider value={methods}>
      <ReactHookFormProvider {...methods}>{children}</ReactHookFormProvider>
    </FormExportContext.Provider>
  );
};
