export const USER_API = 'cloud/api/get-user';

export const VIEWER_GET_INFO_API = 'cloud/api/v2/design-cases';
export const EXPORT_PRODUCTS_API = 'cloud/api/v2/export-products';
export const PAID_EXPORT_PRODUCTS_API = 'cloud/api/v2/paid-export-product';

export const EXPORT_PRODUCTS_INVOICES_API = 'cloud/api/v2/export-products/invoices';
export const CREDIT_BALANCE_API = 'cloud/api/v2/credits/balance';

export const EXPORT_PAYMENT_QUEUE_API = 'cloud/api/v3/payment/payment-queue';

export const POST_CASE_REFRESH_TOKEN_ISSUANCE_API =
  'account/auth/token/refresh';
export const DESIGN_CASE_VALIDATE_API =
  'cloud/api/v3/batch/design-cases/tooth-numbers/validate';
export const STORAGE_PLAN_MY_STORAGE_PLAN =
  'cloud/api/v2/storage-plans/my-storage-plan';
