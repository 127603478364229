import { useEffect, useRef, useState } from 'react';
import { ALLOW_ORIGIN_LIST } from '../Constants/iframe';
import { useSetRecoilState } from 'recoil';
import { exportDialogueAtom } from '../Atoms/exportDialogue';
import { getReferrer } from '../Utils/getReferrer';

export interface IInitialData {
  caseUniqueIds: string[];
  isValid: boolean;
}
export const useGetInitialDataFromHost = () => {
  const caseUniqueIdsRef = useRef<string[]>([]);
  const setExportDialogue = useSetRecoilState(exportDialogueAtom);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryCaseUniqueIds = queryParams.get('caseUniqueIds')?.split(',');
    if (queryCaseUniqueIds && queryCaseUniqueIds.length > 0) {
      caseUniqueIdsRef.current = queryCaseUniqueIds;
      setDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        !dataLoaded &&
        ALLOW_ORIGIN_LIST.includes(event.origin || getReferrer())
      ) {
        const { caseUniqueIds: messageCaseUniqueIds, isAllowDownload } =
          event.data || {};
        if (messageCaseUniqueIds && messageCaseUniqueIds.length) {
          caseUniqueIdsRef.current = messageCaseUniqueIds;
          if (isAllowDownload !== undefined && isAllowDownload === false) {
            setExportDialogue((prev) => ({ ...prev, isAllowDownload }));
          }
          setDataLoaded(true);
        } else setExportDialogue((prev) => ({ ...prev, isUnknownError: true }));
      }
    };

    if (!dataLoaded) window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dataLoaded, setExportDialogue]);

  return {
    caseUniqueIds: caseUniqueIdsRef.current,
    isValid: !!caseUniqueIdsRef.current.length,
    isLoaded: dataLoaded
  };
};
