import { ALLOW_ORIGIN_LIST } from '../Constants/iframe';
import { IExportFile } from '../Types/exportFile';
import { getReferrer } from './getReferrer';

export const onSuccess = (response: IExportFile[]) => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage(
      { message: 'onSuccess', data: response },
      referrer
    );
  }
};

export const onCloseApp = () => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage({ message: 'onClose' }, referrer);
  }
  console.log('App onClose request');
};

export const onError = () => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage({ message: 'onError' }, referrer);
  }
  console.log('App onError request');
};
