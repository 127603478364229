import {
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Loading
} from '@imago-cloud/design-system';
import { Stack, Skeleton } from '@mui/material';

export const AppLoading = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        zIndex: '9999'
      }}
    >
      <Loading size="44" type="basic" />
    </Stack>
  );
};

export const AppSkeleton = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'absolute',
        zIndex: '9999'
      }}
    >
      <DialogueTitle
        sx={{
          padding: '22px 20px 14px 28px'
        }}
      >
        <Skeleton variant="rounded" width={210} height={28} />
      </DialogueTitle>
      <DialogueContent
        sx={{
          height: '498px',
          paddingTop: '12px !important',
          padding: '12px 28px 24px 28px !impotant'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            gap: '24px'
          }}
        >
          <Stack sx={{ width: '512px', height: '100%', gap: '28px' }}>
            <Skeleton variant="rounded" width={'100%'} height={'100%'} />
          </Stack>
          <Stack
            sx={{
              width: '288px',
              height: '100%',
              gap: '24px'
            }}
          >
            <Skeleton variant="rounded" width={'100%'} height={'100%'} />
            <Skeleton variant="rounded" width={'100%'} height={'100%'} />
          </Stack>
        </Stack>
      </DialogueContent>
      <DialogueActions
        sx={{
          width: '100%',
          height: '68px'
        }}
      >
        <Skeleton variant="rounded" width={88} height={36} />
      </DialogueActions>
    </Stack>
  );
};
