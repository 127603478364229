import { IProductListItem } from '../Atoms/setup';

export const FORM_NAME = {
  SELECTED_PRODUCT_LIST: 'selectedProductList',
  CROWN_AXIS_CONFIGURATION: 'crownAxisConfiguration',
  INCLUDE_IMPORTED_FILES: 'includeImportedFiles'
};

export const FORM_DEFAULT_VALUES = {
  [FORM_NAME['SELECTED_PRODUCT_LIST']]: [],
  [FORM_NAME['CROWN_AXIS_CONFIGURATION']]: 'According to CAM',
  [FORM_NAME['INCLUDE_IMPORTED_FILES']]: false
};

export interface IForm {
  selectedProductList: IProductListItem[];
  crownAxisConfiguration: 'According to CAM' | 'Based on Scan data';
  includeImportedFiles: boolean;
}
