import { Dialogue } from '@imago-cloud/design-system';

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Dialogue
      id="export_module_dialog"
      open
      disableBackdropClick
      PaperProps={{
        sx: {
          width: '880px !important',
          maxWidth: '880px',
          height: '634px',
          maxHeight: '634px !important',
          margin: '0px'
        }
      }}
    >
      {children}
    </Dialogue>
  );
};
