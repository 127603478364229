import { RouteObject, useRoutes } from 'react-router-dom';
import { AppSuspense } from './AppSuspense';
import { UnknownErrorOccuredDialogue } from './Components/Error/Dialogue/UnknownErrorOccuredDialogue';

export const routePath: RouteObject[] = [
  {
    path: '/export',
    element: <AppSuspense />,
    errorElement: <div />
  },
  {
    path: '*',
    element: (
      <UnknownErrorOccuredDialogue errorMessage="access app: invalid path" />
    ),
    errorElement: <div />
  }
];

export default function Routers() {
  const routes = useRoutes(routePath);
  return routes;
}
