import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import { IThemeProvider } from './IthemeProvider';
import RecoilNexus from 'recoil-nexus';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth } from './Auth';
import { FormProvider } from './FormProvider';
import { ENVIRONMENT } from './config';
import { AppWrapper } from './Components/AppWrapper';
import Routers from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import packageJson from '../package.json';
import './i18n';
import GoogleAnalytics from './GoogleAnalytics';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
console.debug(
  `#EXPORT_MODULE: ${ENVIRONMENT.toUpperCase()} v${packageJson.version}`
);
root.render(
  <React.StrictMode>
    <AppWrapper>
      <HelmetProvider>
        <QueryClientProvider client={new QueryClient()}>
          <RecoilRoot>
            <RecoilNexus />
            <Router>
              <IThemeProvider>
                <FormProvider>
                  <Auth>
                    <GoogleAnalytics />
                    <Routers />
                  </Auth>
                </FormProvider>
              </IThemeProvider>
            </Router>
          </RecoilRoot>
        </QueryClientProvider>
      </HelmetProvider>
    </AppWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
