export const CONFIGURATION = {
  INCLUDE_IMPORTED_FILES: 'includeImportedFiles',
  SEND_TO_CAM_SOFTWARE: 'sendToCAMsoftware',
  PAYMENT_SECTION: 'paymentSection',
  COST_LABEL: 'costLabel'
};

export const APP_CONFIG = {
  DENTBIRD: {
    SINGLE: {
      [CONFIGURATION['INCLUDE_IMPORTED_FILES']]: true,
      [CONFIGURATION['SEND_TO_CAM_SOFTWARE']]: true,
      [CONFIGURATION['PAYMENT_SECTION']]: true,
      [CONFIGURATION['COST_LABEL']]: 'Cost'
    },
    MULTIPLE: {
      [CONFIGURATION['INCLUDE_IMPORTED_FILES']]: false,
      [CONFIGURATION['SEND_TO_CAM_SOFTWARE']]: false,
      [CONFIGURATION['PAYMENT_SECTION']]: true,
      [CONFIGURATION['COST_LABEL']]: 'Cost'
    }
  },
  DENTBIRD_BATCH: {
    SINGLE: {
      [CONFIGURATION['INCLUDE_IMPORTED_FILES']]: true,
      [CONFIGURATION['SEND_TO_CAM_SOFTWARE']]: false,
      [CONFIGURATION['PAYMENT_SECTION']]: true,
      [CONFIGURATION['COST_LABEL']]: 'Cost'
    },
    MULTIPLE: {
      [CONFIGURATION['INCLUDE_IMPORTED_FILES']]: false,
      [CONFIGURATION['SEND_TO_CAM_SOFTWARE']]: false,
      [CONFIGURATION['PAYMENT_SECTION']]: true,
      [CONFIGURATION['COST_LABEL']]: 'Cost'
    }
  }
};
