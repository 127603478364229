import { normalizeUrl } from './normalize';

export const getReferrer = () => {
  const referrer = document.referrer;

  if (!referrer) {
    const isElectronApp = navigator.userAgent.includes('Electron');
    if (isElectronApp) return 'file://';
    else {
      console.error('invalid origin');
      return '';
    }
  } else return normalizeUrl(referrer);
};
