import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './resources/en';
import ja from './resources/ja';

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    ja: ja
  },
  ns: ['lang'],
  lng: 'en'
});

export default i18n;
